<template>
  <CheckoutLayout :step="3">
    <div class="payment-complete">
      <div class="row">
        <div class="col-12">
          <div class="info-box">
            <h1 class="info-box__header">
              Płatność ukończona
            </h1>

            <p class="info-box__text info-box__text--small">
              Nr zamówienia: {{ orderId }}
            </p>

            <p class="info-box__file-wrap">
              <a
                :href="downloadUrl"
                class="info-box__cta info-box__cta--download"
              >
                Pobierz zakupione zdjęcia
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </CheckoutLayout>
</template>

<script>
import CheckoutLayout from '../checkout-layout/checkout-layout.vue';
import getProductDownloadUrl from '@/vue/utils/getProductDownloadUrl';

export default {
  name: 'PaymentComplete',
  components: {
    CheckoutLayout,
  },
  props: {
    orderId: {
      required: true,
      type: String,
    },
  },
  computed: {
    downloadUrl() {
      return getProductDownloadUrl(this.orderId);
    },
  },
  metaInfo() {
    return {
      title: `Płatność ukończona | nr zamówienia ${this.orderId}`,
    };
  },
};
</script>
